/* // APP GENERIC // */
body {
  margin: 0;
}


#root {
  display: flexbox;
  margin: 0
}

.App {
  min-height: 800px;
  background-color: #ffffff;
}

/* Error Message Styles */
.error-message {
  color: #ff0000;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* // APP GENERIC END // */

/* NAVBAR */

.navbar {
  width: 100%;
}

.navbar nav {
  position: sticky;
  height: 10%;
  background-color: rgb(228, 228, 229);
  display: flex;
  justify-content: right;
}

.navbar-header {
  display: flex;
}

.navbar-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem
}

.icon-bar {
  width: 35px;
  height: 4px;
  background-color: rgb(59, 84, 200);
  margin: 4px 0;
  transition: 0.4s;
}

.icon-bar.open {
  background-color: #333;
  /* Hide the middle bar when the drawer is open */
}

.navbar-drawer {
  display: none;
  flex-direction: column;
  padding: 0rem;
}

.navbar-drawer.open {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* Align items to the right */
}

.navbar-drawer.open ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: max-content;
  align-self: flex-end;
}

.navbar-drawer.open li {
  padding: 0;
}

button[type="nav-button"] {
  background-color: #e4e4e5;
  color: #00050b;
  border-style: solid;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-radius: 0;
  font-size: 1.5rem;
  font-weight: 200;
  font-style: initial;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow';
  cursor: pointer;
  white-space: nowrap;
  width: 15rem;
  padding: 0.5rem
}

button[type="nav-button"]:hover {
  background-color: #3b54c8;
  color: rgb(255, 255, 255)
}

/* Media Query for Larger Screens */
@media screen and (min-width: 411px) {
  /* no queries for navbar */
}

.slider {
  width: 100%;
  margin-top: 15%
}

/* // NAVBAR END // */


/* // BANNER // */
.banner {
  display: flex;
  padding-top: 3%;
  padding-bottom: 1%;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #32275c;
  width: 100%
}

.banner-img {
  max-height: 35%;
  max-width: 85%;
  border-radius: 2vh;
}

.banner-LMS {
  display: flex;
  padding-top: 3%;
  padding-bottom: 1%;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 100%
}

.banner-img-LMS {
  max-height: 35%;
  max-width: 40%;
  border-radius: 2vh;
}

/* // BANNER END // */


/* // ALL PAGES // */

.competitionsPage,
.selectionPage,
.standingsPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #32275c;
}

.homePage,
.accountsPage,
.authPage,
.testingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #ffffff;
}

/* // ALL PAGES END // */


/* // ALL FORMS // */
.form {
  max-width: 100%;
}

.form-group {
  margin-bottom: 5%;
}

.form-group h2 {
  margin-top: 2%;
  margin-bottom: 15%;
  text-decoration: underline;
  font-size: 1.2rem;
}

.form-group h4 {
  margin-bottom: 15%;
  font-size: 0.8rem;
}

.form-group label {
  font-size: 0.9rem;
  display: block;
  margin-bottom: 5%;
  font-weight: bold;
}

.form-group input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.2rem;
  border: 0.02rem solid;
  margin-bottom: 10%;
}

button[type="submit"] {
  background-color: #3b54c8;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #3b54c8;
  color: rgb(255, 255, 255)
}

/* // ALL FORMS END // */



/* // ALL GENERAL BUTTONS // */
button[type="button"] {
  background-color: #3b54c8;
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
  white-space: nowrap;
  /* Prevent text from wrapping within the button */
}

button[type="button"]:hover {
  background-color: #3b54c8;
  color: rgb(255, 255, 255)
}

button[type="button-disabled"] {
  background-color: #ccc;
  color: #797777;
  border: none;
  border-radius: 5px;
  padding: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
  font-size: 0.75rem;
  cursor: not-allowed;
  white-space: nowrap;
  /* Prevent text from wrapping within the button */
}

/* // ALL GENERAL BUTTONS END // */



/* // HOME PAGE // */
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  background-color: #e4e4e5;
  border-radius: 2vh;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  margin: 3%;
  width: 80%
}

.home-container h1 {
  font-weight: 400;
  text-align: center;
  font-size: 2rem;
}

.home-container h2 {
  font-weight: 200;
  text-align: center;
  font-size: 1.5rem;
}

.home-container h3 {
  font-weight: 400;
  text-align: center;
  font-size: 1.2rem;
}

.home-container h4 {
  font-weight: 200;
  text-align: center;
  font-size: 1.2rem;
}

/* // HOME PAGE END // */



/* // LOGIN PAGE // */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-content: center;
  background-color: #e4e4e5;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  padding: 5%;
  margin: 5%;
  max-width: 90%;
  margin-bottom: 5rem;
}

/* // LOGIN PAGE END // */

/* // ACCOUNTS PAGE // */
.accounts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-content: center;
  background-color: #e4e4e5;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  padding: 5%;
  margin: 5%;
  max-width: 90%;
  margin-bottom: 5rem;
}

/* // ACCOUNTS PAGE END // */

/* // COMPETITIONS PAGE // */
.leagues-home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  padding-top: 3%;
  background-color: #e4e4e5;
  border-radius: 2vh;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  margin: 5%;
  margin-top: 3%;
  width: 80%
}

.leagues-home-container h1 {
  font-weight: 400;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0%;
}

.leagues-home-container h2 {
  font-weight: 200;
  text-align: center;
  font-size: 1.5rem;
}

.leagues-home-container h3 {
  font-weight: 600;
  text-align: center;
  font-size: 1.4rem;
  margin-top: 0.5rem;
}

.header-stats {
  display: flex;
  gap: 20px;
}

.header-stats h4 {
  font-weight: 400;
  text-align: center;
  font-size: 1.2rem;
}

.leagues-home-nav-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.leagues-home-nav-buttons button {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0%
}

.competition-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  background-color: #e4e4e5;
  border-radius: 10px;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  width: 60%;
  padding: 5%;
  margin: 5%;
  margin-top: 0;
}

.competition-container select {
  min-width: 50%;
  padding: 1.5%;
  margin-top: 5%
}

.competition-container input {
  padding: 1.5%;
  margin-top: 5%
}

.competition-container h1 {
  font-size: 2rem;
  font-weight: 800;
}

.competition-container h2 {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 5%;
  padding-right: 5%;
}

.competition-container h3 {
  font-size: 1.1rem;
}

.competition-container h4 {
  font-size: 0.9rem;
}

.competition-checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  background-color: #e4e4e5;
  border-radius: 10px;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  width: 60%;
  padding: 5%;
  padding-bottom: 3%;
  margin: 5%;
  margin-top: 0;
}

.competition-checkout-container form {
  width: 80%
}

.competition-checkout-container select {
  width: max-content;
  padding: 0.4rem;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* Align text within the select element */
}

.competition-checkout-container input {
  width: 90%;
  padding: 0.4rem;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* Align text within the select element */
}

.competition-checkout-container h1 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.competition-checkout-container h2 {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.competition-checkout-container h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.competition-checkout-container h4 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.register-accounts h1 {
  font-size: 2rem;
  font-weight: 800;
}

.register-accounts h2 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5%;
}

.register-accounts h3 {
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 3%;
}

.register-accounts input {
  font-size: 1.1rem;
  padding: 1.5%;
  margin-bottom: 5%;
}

.createLeagueIntro {
  width: 80%;
}

.createLeagueIntro h1 {
  font-size: 2rem;
  font-weight: 800;
}

.createLeagueIntro h2 {
  font-size: 1rem;
  font-weight: 600;
}

.createLeagueIntro h3 {
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: underline;
}

.createLeagueNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 2rem;
}

/* // STRIPE PAYMENT ELEMENTS // */

.payment-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

@media (min-width: 600px) {
  .payment-form {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-group {
    flex: 1 1 calc(33.333% - 10px);
    margin-right: 15px;
  }
}


/* // LEAGUES PAGE END // */

/* // SELECTIONS PAGE // */
.account-container,
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #e4e4e5;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  margin: 2%;
  max-width: 80%;
}

.header-container h1 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #ff0000;
}

.account-container h2 {
  margin-bottom: 10%;
}

.fixtures_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3%;
  padding-top: 0.2rem;
  background-color: rgba(181, 213, 215, 0);
  border-style: none;
  border-radius: 1vh;
  margin: 1%;
  padding-bottom: 0%;
  width: 80%;
}

.fixture_item {
  list-style: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2%;
  margin-bottom: 3%;
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  border: 1px solid black;
  background-color: #e4e4e5;
  border-radius: 3vh;
  width: 95%;
}

.team_grids,
.selected_team,
.winning_selection,
.voided_selection,
.losing_selection {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  grid-template-rows: 4fr 1fr;
  gap: 0rem;
  text-align: center;
  place-items: center;
  border-radius: 1vh;
  max-width: 60%;
  max-height: 60%;
}

.team_grids img,
.selected_team img,
.winning_selection img,
.voided_selection img,
.losing_selection img {
  max-height: 95%;
  max-width: 95%;
  align-self: center;
}

.team_grids P,
.selected_team P,
.winning_selection P,
.voided_selection P,
.losing_selection P {
  font-weight: bold;
  margin: 0;
  text-align: center;
  font-size: 0.6rem;
}

.selected_team {
  background-color: rgb(229, 255, 0);
}

.winning_selection {
  background-color: rgb(144, 217, 120);
}

.voided_selection {
  background-color: rgb(212, 214, 212);
}

.losing_selection {
  background-color: rgb(243, 74, 74);
}

.centre_grid {
  display: grid;
  border-style: solid;
  border-width: 0px;
  border-color: #10375c;
  text-align: center;
  min-width: 35%;
  max-height: 50%;
}

.date P {
  display: grid;
  margin: 0px;
  font-size: 0.9rem;
  margin: 0.5rem;
}

.kickoff P {
  display: grid;
  margin: 0px;
  font-size: 0.9rem;
  margin: 0.5rem;
}

.score P {
  display: grid;
  margin: 0px;
  font-size: 0.9rem;
  margin: 0.5rem;
}

.elapsed P {
  display: grid;
  margin: 0px;
  font-size: 0.9rem;
  margin: 5px;
}

.game-week {
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}

h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.gw_text {
  font-size: 1rem;
}

.gw_date {
  font-size: 1rem;
  font-weight: bold;
}

/* // SELECTIONS PAGE END // */



/* // STANDINGS PAGE // */
.standings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #e4e4e5;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  margin: 2%;
  width: 80%;
  min-height: 25rem;
  margin-bottom: 15%;
}

.filter-buttons-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 1%;
  margin-bottom: 5%;
  width: 100%;
}

.standings-container h2 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.standings-container h3 {
  font-size: 1.0rem;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}

.standings-component {
  width: min-content;
}

/*STANDINGS TABLE*/
.standings-header-rounds {
  font-weight: bold;
  border: 0.02rem solid;
  width: 0.75rem;
  padding: 0.06rem;
  margin: 0rem;
  background-color: rgb(126, 138, 150);
  font-size: 0.75rem;
  background-color: rgb(126, 138, 150);
}

.standings-firstcol {
  font-weight: bold;
  border: 0.02rem solid;
  min-width: 6rem;
  padding: 0.06rem;
  margin: 0rem;
  background-color: rgb(126, 138, 150);
  font-size: 0.7rem;
}

.standings-cell-missed,
.standings-cell-lost,
.standings-cell-empty,
.standings-cell-won,
.standings-cell-void,
.standings-cell-pending {
  padding-top: 0.2rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0rem;
  border: 0.02rem solid;
  border-color: #3a3939;
  width: 5%
}

.standings-cell-empty {
  background-color: #e4e4e5;
  border-color: #e4e4e5;
}

.standings-cell-missed,
.standings-cell-lost {
  background-color: rgb(181, 118, 116);
}

.standings-cell-won {
  background-color: rgb(97, 193, 121);
}

.standings-cell-void {
  background-color: rgb(212, 214, 212);
}

.standings-cell-pending {
  background-color: rgb(200, 225, 12);
}

.standings-img {
  width: 1.5rem;
  height: 1.5rem
}

/* Filter Buttons */
button[type="filter-button"] {
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 10%;
  font-size: 0.75rem;
  cursor: pointer;
  white-space: nowrap;
  width: 5rem
    /* Prevent text from wrapping within the button */
}

button[type="filter-button"]:hover {
  background-color: #3b54c8;
  color: rgb(255, 255, 255)
}

/* // STANDINGS PAGE END // */



/* // TESTING PAGE // */
.test-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #e4e4e5;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  margin: 2%;
  width: 80%;
}

.test-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #e4e4e5;
  border-radius: 1rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  margin: 2%;
  width: 80%;
}

/* // TESTING PAGE END // */